//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'
import { sumBy } from 'lodash'
import draggable from "vuedraggable";
import { metaMixin } from '@/mixins/metaMixin'
import dayjs from 'dayjs'
let utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const {required, sameAs, requiredIf, numeric} = require('vuelidate/lib/validators');

export default {
    name: 'Booking',
    mixins: [metaMixin],
    components: { draggable },
    head: {
        title: 'Booking - ESSE',
    },
    data: function () {
        return {
            loading: {
                data: false,
                save: false,
                employees: false,
                services: false,
                feedback: false,
                promo_code: false,
            },
            business: {
                id: null,
                name: '',
                address: '',
            },
            steps: ['service_details', 'payment', 'confirmation'],
            payment_methods: [],
            credit_cards: [],
            step: 1,
            step_1: {
                name: '',
                phone: '',
                terms: false,
                date: null,
                time_slot: null,
                services: [],
            },
            step_2: {
                promo_code: null,
                payment_method: 'card',
                payment_card: null,
                save_card: false,
            },
            feedback: {
                sent: false,
                rating: 3,
                content: '',
            },
            reminder_email: true,
            reminder_sms: true,
            promo_code: '',
            employees: [],
            services: [],
            time_slots: [],
            saved_booking: {
                id: null,
                business_name: '',
                address: '',
                price: 0,
                employee_name: ""
            },
            edit_service_index: null,
            stripe_public_key: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        total_services(){
            return sumBy(this.step_1.services, 'price')
        },
        promo_code_value(){
            if (!this.step_2.promo_code) {
                return 0
            }
            if (this.step_2.promo_code.type === 'percent') {
                return this.step_2.promo_code.amount * this.total_services
            } else {
                return this.step_2.promo_code.amount < this.total_services ? this.step_2.promo_code.amount : this.total_services
            }
        },
        total() {
            return this.total_services - this.promo_code_value;
        },
    },
    created() {
        this.stripe_public_key = process.env.STRIPE_PK
        this.step_1.date = dayjs().format('YYYY-MM-DD');
    },
    mounted() {
        this.getData();
        this.getCreditCards();
        this.step_1.name = this.user.first_name + ' ' + this.user.last_name;
        this.step_1.phone = this.user.phone;
    },
    watch: {
        '$route.query.serviceId': function() {
            this.setServices()
        },
        'step_2.payment_card': function() {
            this.step_2.save_card = false
        },
        'step_1.time_slot': function() {
            this.setServicesTimeSlots()
        },
        'reminder_email': function(value) {
            if (!this.saved_booking.id) {
                return
            }
            this.$axios.post("/booking/set-reminder", {id: this.saved_booking.id, value: value, type: 'email'});
        },
        'reminder_sms': function(value) {
            if (!this.saved_booking.id) {
                return
            }
            this.$axios.post("/booking/set-reminder", {id: this.saved_booking.id, value: value, type: 'sms'});
        },
        step() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
    validations: function() {
        let rules = {
            step_1: {
                name: {required},
                phone: {required, numeric},
                terms: { required, sameAs: sameAs(() => true) },
                services: {required},
                date: {required},
                time_slot: {required},
            },
            step_2: {
                payment_method: {required},
                payment_card: {requiredIf: requiredIf((model) => model.payment_method === 'card')}
            }
        };
        return rules;
    },
    methods: {
        setServices () {
            if (!this.$route.query.serviceId) {
                return;
            }
            const service_ids = String(this.$route.query.serviceId).split(',')
            for (let i in service_ids) {
                const foundService = this.services.find(service => service.id == service_ids[i]);
                if (foundService.employees.length === 1 || this.business.type === 1) {
                    foundService.employee = JSON.parse(JSON.stringify(foundService.employees[0]))
                }
                if (foundService) {
                    this.step_1.services.push(foundService);
                }
            }
            this.getTimeSlots()
        },
        getData() {
            this.loading.data = true;
            this.$axios.post("/businesses/details", {businessSlug: this.$route.params.businessSlug})
                .then(async (res) =>{
                    this.business = res.business;
                    await this.getServices();
                    this.loading.data = false;
                    this.loading.search = false;
                })
                .catch(()=> {
                    this.$router.push({ name: 'home' })
                    this.loading.data = false;
                    this.loading.search = false;
                });
        },
        prevStep () {
            this.step--;
        },
        nextStep () {
            if (this.step === 1) {
                this.$v.step_1.$touch();
                if (this.$v.step_1.$invalid == true) {
                    return false;
                }
                this.step++;
            } else if (this.step === 2) {
                this.$v.step_2.$touch();
                if (this.total > 0 && this.$v.step_2.$invalid == true) {
                    return false;
                }
                this.saveBooking();
            }
        },
        selectStep(step) {
            if(this.saved_booking.id) {
                return
            }
            if (step > this.step || step === 3) {
                return false;
            }
            this.step = step
        },
        setServicesTimeSlots () {
            if (this.step_1.time_slot) {
                let index = this.time_slots.findIndex(slot => slot === this.step_1.time_slot)
                let positions = 0
                for (let i in this.step_1.services) {
                    this.step_1.services[i].time_slot = this.time_slots[index + positions]
                    positions += Math.ceil(this.step_1.services[i].duration / 15)
                }
            }else{
                for (let i in this.step_1.services) {
                    this.step_1.services[i].time_slot = null
                }
            }
        },
        selectTimeSlot(time_slot) {
            this.step_1.time_slot = time_slot
        },
        selectDate(date) {
            this.step_1.date = date;
            this.getTimeSlots()
        },
        editEmployee(index) {
            this.edit_service_index = index
            this.employees = this.step_1.services[index].employees;
            // this.getEmployees(this.step_1.services[index].id)
            this.$modal.show('selectEmployeeModal')
        },
        selectEmployee(employee) {
            this.step_1.services[this.edit_service_index].employee = JSON.parse(JSON.stringify(employee));
            this.$modal.hide('selectEmployeeModal')
            this.getTimeSlots()
        },
        editService (index) {
            this.edit_service_index = index
            this.$modal.show('selectServiceModal')
        },
        showAddService () {
            this.edit_service_index = null
            this.$modal.show('selectServiceModal')
        },
        selectService(service) {
            if (service.employees.length === 1 || this.business.type === 1) {
                service.employee = JSON.parse(JSON.stringify(service.employees[0]))
            }
            if (this.edit_service_index !== null) {
                this.step_1.services[this.edit_service_index] = JSON.parse(JSON.stringify(service));
            }else{
                this.step_1.services.push(JSON.parse(JSON.stringify(service)));
            }
            this.$modal.hide('selectServiceModal')
            this.getTimeSlots()
            this.setServicesTimeSlots()
        },
        removeService(index) {
            this.$dialog.confirm(this.$t('alert_are_you_sure'), {
                okText: this.$t('yes'),
                cancelText: this.$t('no')
            })
            .then(() => {
                this.step_1.services.splice(index, 1);
                this.getTimeSlots()
            })
        },
        addToCalendar() {

        },
        async getServices() {
            this.loading.services = true;
            await this.$axios.post("/businesses/services", {id: this.business.id, with: ['employees']})
                .then((res) =>{
                    this.services = res.data;
                    this.setServices()
                    this.loading.services = false;
                })
                .catch(()=> {
                    this.loading.services = false;
                });
        },
        getEmployees(service_id) {
            this.loading.employees = true;
            this.$axios.post("/businesses/employees", {id: this.business.id, service_id: service_id})
                .then((res) =>{
                    this.employees = res.data;
                    this.loading.employees = false;
                })
                .catch(()=> {
                    this.loading.employees = false;
                });
        },
        getTimeSlots() {
            if(!this.business.id || !this.step_1.services.length || !this.step_1.date) {
                return;
            }
            this.loading.time_slots = true;
            this.$axios.post("/businesses/time-slots", {
                id: this.business.id,
                services: this.step_1.services,
                date: this.step_1.date
            })
                .then((res) =>{
                    this.time_slots = res.time_slots;
                    this.step_1.time_slot = this.time_slots[0] ?? null
                    this.loading.time_slots = false;
                })
                .catch(()=> {
                    this.loading.time_slots = false;
                });
        },
        startPayment () {
            this.loading.save = true;
            if (this.step_2.payment_card === 'new') {
                this.loading.payment = true;
                this.$refs.newCard.submit();
            } else {
                this.makePayment({
                    credit_card_id: this.step_2.payment_card
                })
            }
        },
        saveBooking() {
            if (this.saved_booking.id) {
                this.startPayment();
                return;
            }
            this.loading.save = true;
            this.step_1.businessId = this.business.id;
            this.$axios.post("/businesses/booking/save", { ...this.step_1, ...{payment: this.payment, promo_code: this.step_2.promo_code?.code} })
                .then((res) =>{
                    this.saved_booking = res.booking;
                    this.feedback.booking_id = res.booking.id;
                    this.feedback.business_id = this.business.id;
                    this.$store.commit('setActiveBookingsCount', res.active_bookings_count);
                    this.startPayment()
                })
                .catch(()=> {
                    this.loading.save = false;
                });
        },
        makePayment(request) {
            this.$axios.post("/booking/make-payment", { ...request, ...{booking_id: this.saved_booking.id} })
                .then((res) =>{
                    if (res.status === 'requires_action') {
                        let stripe = Stripe(process.env.STRIPE_PK, {
                            stripeAccount: res.stripe_account
                        })
                        stripe.handleCardAction(res.client_secret).then((result) => this.handleStripeJsResult(result)).catch(e => console.log(e))
                    } else {
                        this.loading.save = false;
                        this.payment = res.payment;
                        if (this.step === 2) {
                            this.step++;
                        }
                    }

                })
                .catch(()=> {
                    this.loading.save = false;
                });
        },
        handleStripeJsResult (result) {
            if (result.error) {
                this.$toast.error(result.error)
                this.loading.save = false;
            } else {
                this.$axios.post("/payments/confirm-intent", { payment_intent_id: result.paymentIntent.id })
                    .then((res) =>{
                        this.loading.save = false;
                        this.payment = res.payment;
                        if (this.step === 2) {
                            this.step++;
                        }
                    })
                    .catch(()=> {
                        this.loading.save = false;
                    });
            }
        },
        sendFeedback() {
            this.loading.feedback = true;
            this.feedback.businessId = this.business.id;
            this.$axios.post("/businesses/feedback/save", this.feedback)
                .then((res) =>{
                    this.feedback.sent = true;
                    this.feedback.content = '';
                    this.loading.feedback = false;
                })
                .catch(()=> {
                    this.loading.feedback = false;
                });
        },
        goBack () {

        },
        getCreditCards() {
            if (!this.user.id) {
                return;
            }
            this.loading.credit_cards = true;
            this.$axios.get("/account/credit-cards")
                .then((res) =>{
                    this.credit_cards = res.data;
                    this.loading.credit_cards = false;
                })
                .catch(()=> {
                    this.loading.credit_cards = false;
                });
        },
        addPromoCode() {
            if (!this.promo_code) {
                return;
            }
            this.loading.promo_code = true;
            this.$axios.post("/businesses/check-promocode", {
                businessId: this.business.id,
                code: this.promo_code,
            })
                .then((res) =>{
                    this.step_2.promo_code = res.promo_code
                    this.loading.promo_code = false;
                })
                .catch(()=> {
                    this.loading.promo_code = false;
                });
        },
        removePromoCode () {
            this.$dialog.confirm(this.$t('alert_are_you_sure'), {
                okText: this.$t('yes'),
                cancelText: this.$t('no')
            })
            .then(() => {
                this.loading.promo_code = true;
                this.$axios.post("/businesses/remove-promocode", {
                        businessId: this.business.id,
                        code: this.step_2.promo_code.code,
                })
                    .then(() =>{
                        this.step_2.promo_code = null;
                        this.loading.promo_code = false;
                    })
                    .catch(()=> {
                        this.loading.promo_code = false;
                    });
            })
        }
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'
import Languages from '@/pages/Account/Languages'
import { initMixin } from '@/mixins/initMixin'
// import { FadeTransition } from 'vue2-transitions'

export default {
    name: 'layout',
    components: { Languages },
    middleware: 'init',
    mixins: [initMixin],
    // components: { FadeTransition },
    data: function () {
        return {
            owners_url: process.env.OWNERS_URL,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            meta: state => state.meta,
            service_types: state => state.service_types,
            loaded: state => state.loaded,
        })
    },
    mounted () {
        this.loadCrisp()
    },
    methods: {
        setCrispUser () {
            $crisp.push(["set", "user:email", [this.user.email]])
            $crisp.push(["set", "user:nickname", [this.user.first_name + ' ' + this.user.last_name]]);
        },
        loadCrisp () {
            if (window.CRISP_WEBSITE_ID) {
                return;
            }
            window.$crisp=[];
            window.CRISP_WEBSITE_ID="f9d25d97-bd9a-43c7-baf7-a3be1d440ba4";
            (function(){
                let d=document;
                let s=d.createElement("script");
                s.src="https://client.crisp.chat/l.js";
                s.async=1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
            this.setCrispUser()
        },
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { metaMixin } from '@/mixins/metaMixin'

export default {
    name: 'InfoPage',
    mixins: [metaMixin],
    data() {
        return {
            loading: {
                data: false
            },
            page: null,
        };
    },
    mounted () {
        this.$events.on('changeLocale', eventData => this.$fetch());
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    watch: {
          '$route.path': function () {
              this.getData()
          }
    },
    beforeDestroy() {
        this.$events.$off('changeLocale')
    },
    async fetch() {
        await this.getData();
    },
    methods: {
        getData () {
            this.loading.data = true;
            return this.$axios.post("/get-page", {code: this.$route.meta.code})
                .then((res) =>{
                    this.page = res.page;
                    this.meta.title = res.seo.title ? res.seo.title : res.page.title
                    this.meta.description = res.seo.description ? res.seo.description : ''
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false;
                });
        },
    }
};

import { render, staticRenderFns } from "./BusinessDetails.vue?vue&type=template&id=253a71fe&"
import script from "./BusinessDetails.vue?vue&type=script&lang=js&"
export * from "./BusinessDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Stars: require('/Users/tudorserban/Projects/esse-fe/components/Stars.vue').default,BusinessServices: require('/Users/tudorserban/Projects/esse-fe/components/BusinessServices.vue').default,BusinessReviews: require('/Users/tudorserban/Projects/esse-fe/components/BusinessReviews.vue').default,MapPosition: require('/Users/tudorserban/Projects/esse-fe/components/MapPosition.vue').default,BusinessSchedule: require('/Users/tudorserban/Projects/esse-fe/components/BusinessSchedule.vue').default,ReviewModal: require('/Users/tudorserban/Projects/esse-fe/components/ReviewModal.vue').default})

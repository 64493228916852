//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex'
import {FadeTransition} from 'vue2-transitions'

export default {
    name: 'AccountLayout',
    components: { FadeTransition },
    head: {
        title: 'My Account - ESSE',
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: 'User account'
            }
        ],
    },
    data: function () {
        return {

        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            meta: state => state.meta,
            settings: state => state.settings,
        })
    },
    created() {
        // this.computeMenu();
        // this.$router.beforeEach((to, from, next) => {
        //     this.closeMenu();
        //     next();
        // })
    },
    mounted() {
        if (this.$route.name === 'account') {
            this.$router.push({ name: 'account.info' })
        }
        // this.$events.$on('toggleMenu', eventData => this.toggleMenu());
    },
    destroyed() {
        // this.$events.$off('toggleMenu');
    },
    methods: {
        logout() {
            this.$dialog.confirm(this.$t('alert_logout'), {
                okText: this.$t('yes'),
                cancelText: this.$t('no')
            })
                .then(() => {
                    this.$auth.destroyToken();
                    this.$acl.change('public');
                    this.$store.commit('logout');
                    this.$router.push({ name: 'home' })
                });
        },
    }
}

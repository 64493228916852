//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { modelListMixin } from '~/mixins/modelListMixin'
import { metaMixin } from '@/mixins/metaMixin'

export default {
    name: 'Search',
    mixins: [modelListMixin, metaMixin],
    data: function () {
        return {
            loading: {
                data: false,
                search: false
            },
            sorting_option: null,
            sorting_options: [],
            service_type: null,
            service_category: null,
            location: null,
            services: [],
            businesses: null,
            filters: {
                term : '',
                type: null,
                limit : 10,
                sort: 'name__desc',
                dir: 'asc',
                page : 1,
                city: null,
                distance: 30,
                service_category: null,
                services: [],
                availabilities: [],
                price: 500,
                rating: [],
            },
            week_days: {
                1: 'monday',
                2: 'tuesday',
                3: 'wednesday',
                4: 'thursday',
                5: 'friday',
                6: 'saturday',
                7: 'sunday',
            }
        }
    },
    computed: {
        breadcrumbs () {
            let crumbs = []
            if (this.service_type) {
                crumbs.push({
                    label: this.service_type.name,
                    url: null
                })
            }
            if (this.service_category) {
                crumbs.push({
                    label: this.service_category.name,
                    url: null
                })
            }
            if (this.location) {
                crumbs.push({
                    label: this.location.name,
                    url: null
                })
            }
            return crumbs;
        },
    },
    watch: {
        '$route.path': function () {
            this.$fetch()
        }
    },
    created() {
        this.meta.title = `Search salons, barbershops and spa - ESSE`
        this.$events.on('changeLocale', eventData => this.$fetch());
    },
    beforeDestroy() {
        this.$events.$off('changeLocale')
    },
    async fetch() {
        this.filters.type = this.$route.params.type;
        this.filters.term = this.$route.params.term ?? '';
        this.filters.city = this.$route.params.city ?? null;

        if (this.$route.params.service_category) {
            let service_category = JSON.parse(JSON.stringify(this.$route.params.service_category))
            await this.$axios.post("/get-services", { category_id: service_category.id })
                .then((res) =>{
                    service_category.services = res;
                    this.filters.service_category = service_category
                });
        }else{
            this.filters.service_category = null
        }
        this.filters.services = this.$route.params.service ? [this.$route.params.service] : [];

        await this.getData()
    },
    async activated() {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    deactivated() {
        this.businesses = null;
    },
    methods: {
        getPage(page) {
            this.$smoothScroll({
                scrollTo: document.getElementById('businessesGrid'),
                updateHistory: false,
            })
            this.filters.page = page;
            this.getData();
        },
        search () {
            this.filters.page = 1;
            this.loading.search = true;
            this.getData();
        },
        resetFilters() {
            this.filters = {
                term : '',
                type: null,
                limit : 10,
                sort: 'name__desc',
                dir: 'asc',
                page : 1,
                city: null,
                distance: 30,
                service_category: null,
                services: [],
                availabilities: [],
                price: 500,
                rating: [],
            }
        },
        getData () {
            this.loading.data = true;
            let filters = JSON.parse(JSON.stringify(this.filters))
            if (filters.service_category) {
                filters.service_category = {
                    id: filters.service_category.id
                }
            }
            if (filters.services) {
                filters.services = filters.services.map(service => {
                    return {
                        id: service
                    }
                });
            }
            return this.$axios.post("/businesses", filters)
                .then((res) =>{
                    this.businesses = res.businesses;
                    this.sorting_options = res.sorting_options;
                    this.sorting_option = res.sorting_option;
                    this.service_category = res.service_category;
                    this.services = res.services;
                    this.service_type = res.service_type;
                    this.location = this.filters.city;
                    this.loading.data = false;
                    this.loading.search = false;
                })
                .catch(()=> {
                    this.loading.data = false;
                    this.loading.search = false;
                });
        },
        updateFavorite(business) {
            business.isFavorite = !business.isFavorite;
            this.$axios.post("/businesses/favorite/update", {id: business.id, isFavorite: business.isFavorite})
                .catch(() => {
                    business.isFavorite = !business.isFavorite;
                });
        },
        removeService(service) {
            const index = this.filters.services.findIndex(item => item.id === service.id)
            if (index > -1) {
                this.filters.services.splice(index, 1);
                this.getData();
            }
        },
        setSorting(sorting_option) {
            if (this.$refs['sortdropdown']) {
                this.$refs['sortdropdown'].tip.hide()
            }
            const new_value = sorting_option ? sorting_option.value : null
            if(this.filters.sort !== new_value) {
                this.filters.sort = new_value;
                this.getData();
            }
        },
        showMapModal() {
            this.$modal.show('mapViewModal')
        }
    }
}

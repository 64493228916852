//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'
import { metaMixin } from '@/mixins/metaMixin'

export default {
    name: 'Home',
    transition: 'fade',
    mixins: [metaMixin],
    layout: 'desktop',
    data: function () {
        return {
            loading: {
                data: false,
                featured: false,
                topBusinesses: false,
                videos: false,
            },
            filters: {
                service: '',
                city: '',
                term: '',
            },
            data: {},
            videos: [],
            services: [],
            businesses: [],
            featured: [],
            top_businesses: [],
            futureDestinations: [],
        }
    },
    computed: {
        ...mapState({
            position: (state) => state.position,
        }),
    },
    async fetch () {
        this.meta.title = `Home - ESSE`
        await Promise.all([
            this.getData(),
            this.getTopBusinesses(),
            this.getFeatured(),
            this.getVideos(),
            this.getFutureDestinations()
        ])
    },
    mounted () {
        this.$events.on('changeLocale', eventData => this.$fetch());
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    beforeDestroy() {
        this.$events.$off('changeLocale')
    },
    activated () {
        this.filters = {
            service: '',
            city: '',
            term: '',
        }
    },
    methods: {
        getData () {
            this.loading.data = true
            return this.$axios.get('/homepage')
                .then((res) => {
                    this.data = res
                    if (res.seo.title) {
                        this.meta.title = res.seo.title
                    }
                    this.loading.page = false
                })
                .catch((err) => {
                    this.loading.page = false
                })
        },
        getVideos () {
            this.loading.videos = true
            return this.$axios.get('/home-videos')
                .then((res) => {
                    this.videos = res.videos
                    this.loading.videos = false
                })
                .catch((err) => {
                    this.loading.videos = false
                })
        },
        getTopBusinesses () {
            this.loading.topBusinesses = true
            return this.$axios.post('/top-businesses')
                .then((res) => {
                    this.top_businesses = res
                    this.loading.topBusinesses = false
                })
                .catch((err) => {
                    this.loading.topBusinesses = false
                })
        },
        getFeatured () {
            this.loading.featured = true
            return this.$axios.post('/featured')
                .then((res) => {
                    this.featured = res
                    this.loading.featured = false
                })
                .catch((err) => {
                    this.loading.featured = false
                })
        },
        getFutureDestinations () {
            this.loading.destinations = true
            return this.$axios.get('/future-destinations')
                .then((res) => {
                    this.futureDestinations = res
                    this.loading.destinations = false
                })
                .catch(() => {
                    this.loading.destinations = false
                })
        },
        search () {
            this.$router.push({
                name: 'search',
                params: {
                    term: this.filters.term,
                    service_category: this.filters.service ? this.filters.service.service_category : null,
                    service: this.filters.service ? this.filters.service.id : null,
                    city: this.filters.city
                }
            })
        },
        goToFeaturedRoute (featured) {
            switch (featured.type) {
                case 'Service':
                    this.$router.push({
                        name: 'search', params: {
                            service_category: featured.featured.service_category,
                            service: featured.featured.id
                        }
                    })
                    break
                case 'ServiceType':
                    this.$router.push({
                        name: 'search', params: {
                            type: featured.featured.slug
                        }
                    })
                    break
                case 'ServiceCategory':
                    this.$router.push({
                        name: 'search', params: {
                            service_category: featured.featured
                        }
                    })
                    break
            }
        }
    }
}

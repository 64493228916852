//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { metaMixin } from '@/mixins/metaMixin'

export default {
    name: 'BusinessDetails',
    mixins: [metaMixin],
    data: function () {
        return {
            loading: {
                data: false,
            },
            business: {},
            schedule: null,
        }
    },
    mounted() {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    async fetch() {
        await this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            return this.$axios.post("/businesses/details", {businessSlug: this.$route.params.businessSlug})
                .then((res) =>{
                    this.business = res.business;
                    this.schedule = res.schedule;
                    this.loading.data = false;
                    this.meta.title = `${this.business.name} - ESSE`
                    // this.meta.description = res.data.info.metadescription
                })
                .catch(()=> {
                    this.$router.push({ name: 'home' })
                    this.loading.data = false;
                });
        },
        getLocationUrl () {
            if(this.business.latitude) {
                let coords = encodeURIComponent(`${this.business.latitude},${this.business.longitude}`)
                return `https://www.google.com/maps/search/?api=1&query=${coords}`
            } else {
                return `https://www.google.com/maps/search/?api=1&query=${this.business.address}`
                // return `https://www.google.com/maps/dir/?api=1&destination=${this.business.address}`
            }
        },
    }
}
